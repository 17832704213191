
import { defineComponent, ref, PropType } from "vue";
import Dialog from "@/components/Dialog.vue";
import { useStore } from "vuex";
import { Attachment } from "../@types/kilpailutusasiakirja";
import { fileUrl } from "@/modules/valvontatiedot/api/file";

export default defineComponent({
    components: { Dialog },
    props: {
        file: Object as PropType<Attachment>,
    },
    setup(props) {
        const store = useStore();

        const modalOpen = ref(false);
        const showDialog = () => (modalOpen.value = true);
        const hideDialog = () => (modalOpen.value = false);

        const deleteDocument = () => {
            store.dispatch("kilpailutus/deleteKilpailutusasiakirja", props.file!.uuid);
            hideDialog();
        };

        return {
            modalOpen,
            showDialog,
            hideDialog,
            deleteDocument,
            fileUrl,
        };
    },
});

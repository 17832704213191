
import { defineComponent, computed, Ref, ref } from "vue";
import LocationInput from "@/modules/palveluhaku/components/input/LocationInput.vue";
import MunicipalityInput from "@/modules/palveluhaku/components/input/MunicipalityInput.vue";
import { fileUrl } from "@/modules/valvontatiedot/api/file";

export default defineComponent({
    components: { LocationInput, MunicipalityInput },
    props: {
        modelValue: Object,
        removeFile: Function,
        onFileChanged: Function,
        showError: Boolean,
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const form: any = computed({
            get: () => props.modelValue,
            set: (value) => emit("update:modelValue", value),
        });

        const fileUpload: Ref<HTMLElement | null> = ref(null);

        function onFileUploadClick() {
            fileUpload.value?.click();
        }

        function downloadFile(file: File) {
            const { type, name } = file;
            const blob = new Blob([file], { type });
            const objectURL = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = objectURL;
            a.setAttribute("download", name);
            a.click();
        }

        const getFileUrl = fileUrl;

        return {
            form,
            onFileUploadClick,
            downloadFile,
            fileUpload,
            getFileUrl,
        };
    },
});

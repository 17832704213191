
import { defineComponent, ref, reactive } from "vue";
import Dialog from "@/components/Dialog.vue";
import KilpailutusForm from "./KilpailutusForm.vue";
import { useStore } from "vuex";

export default defineComponent({
    components: { Dialog, KilpailutusForm },
    setup() {
        const initialState: any = {
            hyvinvointialue: null,
            municipality: null,
            keyword: "",
            description: "",
            attachments: [],
        };
        const showError = ref(false);
        const store = useStore();

        const form = reactive({ ...initialState });

        const modalOpen = ref(false);
        const showDialog = () => (modalOpen.value = true);
        const hideDialog = () => (modalOpen.value = false);

        const resetForm = () => {
            Object.assign(form, initialState);
            hideDialog();
        };

        const checkError = () => {
            if (!form.hyvinvointialue) {
                showError.value = true;
            } else {
                showError.value = false;
                saveForm();
            }
        };

        const saveForm = () => {
            const kilpailutustiedot = {
                hyvinvointialue: form.hyvinvointialue?.id,
                municipality: form.municipality?.id,
                keyword: form.keyword,
                description: form.description,
            };
            const data = new FormData();
            data.append("kilpailutustiedot", JSON.stringify(kilpailutustiedot));
            form.attachments.forEach((file: File) => {
                data.append("files[]", file, file.name);
            });

            store.dispatch("kilpailutus/saveKilpailutusasiakirja", data);
            resetForm();
        };

        function onFileChanged(event: any) {
            const newFiles: File[] = [...form.attachments];
            event.target.files.forEach((file: File) => {
                newFiles.push(file);
            });
            form.attachments = newFiles;
        }

        function removeFile(index: number) {
            const newFiles = [...form.attachments];
            newFiles.splice(index, 1);
            form.attachments = newFiles;
        }

        return {
            form,
            modalOpen,
            showDialog,
            hideDialog,
            resetForm,
            checkError,
            showError,
            onFileChanged,
            removeFile,
        };
    },
});

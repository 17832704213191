import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, withScopeId as _withScopeId, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

_pushScopeId("data-v-d95168a8")
const _hoisted_1 = { class: "q-py-md q-gutter-sm" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = ["onClick"]
const _hoisted_5 = {
  key: 0,
  class: "row q-mt-sm"
}
const _hoisted_6 = { class: "text-weight-regular text-black" }
const _hoisted_7 = {
  key: 1,
  class: "row items-center"
}
_popScopeId()

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DeleteKilpailutus = _resolveComponent("DeleteKilpailutus")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_tree = _resolveComponent("q-tree")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_q_tree, {
      nodes: _ctx.tree,
      "node-key": "label",
      "no-connectors": "",
      dense: "",
      expanded: _ctx.expanded,
      "onUpdate:expanded": _cache[0] || (_cache[0] = ($event: any) => (_ctx.expanded = $event)),
      "no-nodes-label": "Kilpailutusasiakirjoja ei löytynyt"
    }, {
      "default-header": _withCtx((prop) => [
        (prop.node.document)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(prop.node.files, (file) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: file.uuid,
                    class: "justify-between items-center q-mr-md"
                  }, [
                    _createElementVNode("a", {
                      onClick: ($event: any) => (_ctx.openDocument(file.uuid)),
                      class: "clickable text-dark-text file-name q-mr-sm"
                    }, _toDisplayString(file.filename), 9, _hoisted_4),
                    (prop.node.userCanDelete)
                      ? (_openBlock(), _createBlock(_component_DeleteKilpailutus, {
                          key: 0,
                          file: file
                        }, null, 8, ["file"]))
                      : _createCommentVNode("", true)
                  ]))
                }), 128))
              ]),
              (prop.node.description)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("span", _hoisted_6, _toDisplayString(prop.node.description), 1)
                  ]))
                : _createCommentVNode("", true)
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createVNode(_component_q_icon, {
                name: 
                            _ctx.expanded.includes(prop.node.label)
                                ? 'fas fa-chevron-down'
                                : 'fas fa-chevron-right'
                        ,
                class: "q-mr-sm"
              }, null, 8, ["name"]),
              _createElementVNode("div", null, _toDisplayString(prop.node.label), 1)
            ]))
      ]),
      _: 1
    }, 8, ["nodes", "expanded"])
  ]))
}
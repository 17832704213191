
import { defineComponent, ref, onMounted, computed } from "vue";
import { useStore } from "vuex";
import DocumentTree from "../components/DocumentTree.vue";
import KilpailutusDialog from "../components/KilpailutusDialog.vue";
import useAuthorisation from "@/utils/useAuthorization";
import { ROLE } from "@/constants/roles";

export default defineComponent({
    components: { DocumentTree, KilpailutusDialog },
    setup() {
        const store = useStore();

        const fetchDocuments = () => store.dispatch("kilpailutus/fetchKilpailutusasiakirjat");
        onMounted(fetchDocuments);

        const kilpailutusasiakirjat = computed(() => {
            return store.state.kilpailutus.kilpailutusasiakirjat;
        });

        const loadingAsiakirjat = computed(() => {
            return store.state.kilpailutus.loadingAsiakirjat;
        });

        const dialogRef = ref();
        const openDialog = () => {
            dialogRef.value.showDialog();
        };
        const { canAccess: canEditKilpailutus } = useAuthorisation([
            ROLE.TYONTEKIJA,
            ROLE.KUNTAMANAGER,
        ]);

        return {
            kilpailutusasiakirjat,
            openDialog,
            dialogRef,
            canEditKilpailutus,
            loadingAsiakirjat,
        };
    },
});

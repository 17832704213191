
import { defineComponent, PropType, computed, ref } from "vue";
import { Kilpailutusasiakirja } from "../@types/kilpailutusasiakirja";
import { LOCATIONS } from "@/modules/palveluhaku/components/input/LocationInput.vue";
import { fileUrl } from "@/modules/valvontatiedot/api/file";
import DeleteKilpailutus from "./DeleteKilpailutus.vue";
import useAuthorisation from "@/utils/useAuthorization";
import { ROLE } from "@/constants/roles";

export default defineComponent({
    components: { DeleteKilpailutus },
    props: {
        kilpailutusasiakirjat: Object as PropType<Kilpailutusasiakirja[]>,
    },
    setup(props) {
        const expanded = ref([]);
        const tree = computed(() => {
            const tree: any[] = [];
            const documents: any[] = props.kilpailutusasiakirjat || [];

            const getMunicipality = (hyveAlue: any, document: any) =>
                hyveAlue.children.find((element: any) => element.label === document.municipality);

            const insertAttachments = (element: any, document: any) => {
                element.children.push({
                    document: true,
                    files: document.attachments,
                    description: document.description,
                    userCanDelete: allowDelete(document.organisaatio.id),
                });
            };

            for (const document of documents) {
                const location = LOCATIONS.find(
                    (location) => location.id === document.hyvinvointialue
                )?.name;
                let hyvinvointialue = tree.find((element) => element.label === location);
                if (!hyvinvointialue) {
                    hyvinvointialue = {
                        label: location,
                        children: [],
                    };
                    tree.push(hyvinvointialue);
                }

                const keyArray = [];
                if (document.municipality) {
                    keyArray.push({
                        searchKeyParent: "hyvinvointialue",
                        searchKeyChild: "municipality",
                    });
                    if (document.keyword) {
                        keyArray.push({
                            searchKeyParent: "municipality",
                            searchKeyChild: "keyword",
                        });
                    }
                } else if (document.keyword) {
                    keyArray.push({
                        searchKeyParent: "hyvinvointialue",
                        searchKeyChild: "keyword",
                    });
                }
                let i = 0;
                for (const keyArrayItem of keyArray) {
                    const parentKey =
                        keyArrayItem.searchKeyParent === "hyvinvointialue"
                            ? hyvinvointialue
                            : getMunicipality(hyvinvointialue, document);
                    const parentElement = parentKey.children;

                    let childElement = parentElement.find(
                        (element: any) => element.label === document[keyArrayItem.searchKeyChild]
                    );

                    if (!childElement) {
                        childElement = {
                            label: document[keyArrayItem.searchKeyChild],
                            children: [],
                        };
                        parentElement.push(childElement);
                    }
                    i += 1;
                    if (i === keyArray.length) {
                        insertAttachments(childElement, document);
                    }
                }
                if (!keyArray.length) {
                    insertAttachments(hyvinvointialue, document);
                }
            }
            const sortedTree = tree.sort((hyve1, hyve2) => (hyve1.label < hyve2.label ? -1 : 1));
            return sortedTree;
        });

        const openDocument = (uuid: string) => {
            window.open(fileUrl(uuid), "_blank");
        };

        const allowDelete = (organisationId: number) => {
            const { organisationMatches } = useAuthorisation(
                [],
                organisationId
            );
            return organisationMatches;
        };

        return { tree, openDocument, expanded, fileUrl };
    },
});

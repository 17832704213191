import { resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_KilpailutusForm = _resolveComponent("KilpailutusForm")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    "modal-open": _ctx.modalOpen,
    title: "Lisää kilpailutusasiakirjoja",
    "on-cancel": _ctx.resetForm,
    "on-save": _ctx.checkError,
    "hide-dialog": _ctx.hideDialog
  }, {
    default: _withCtx(() => [
      _createVNode(_component_KilpailutusForm, _mergeProps(_ctx.$attrs, {
        modelValue: _ctx.form,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.form = $event)),
        "on-file-changed": _ctx.onFileChanged,
        "remove-file": _ctx.removeFile,
        "show-error": _ctx.showError
      }), null, 16, ["modelValue", "on-file-changed", "remove-file", "show-error"])
    ]),
    _: 1
  }, 8, ["modal-open", "on-cancel", "on-save", "hide-dialog"]))
}